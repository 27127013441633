import { useState, useEffect } from 'react';
import { PermissionName, FeatureResponse } from '@models/Permission';
import { hasPermission } from '@util/PermissionUtil';
import { Organization } from '@models/Organization';
import { ProjectType } from '@models/Project';
import useAuth from './useAuth';
import useApi from './useApi';
import Endpoints from '../services/Endpoints';

const useOrganizationPermissions = (organization?: Organization | null) => {
    const { user, authLoading } = useAuth();
    const api = useApi();
    const [features, setFeatures] = useState<FeatureResponse>();
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchFeatures = async () => {
            setError(null);
            try {
                const featureResponse: FeatureResponse = await api.get(Endpoints.organization.features());
                setFeatures(featureResponse);
            } catch {
                setError('Failed to load organization features');
            }
        };
        if (!user || authLoading) return;
        fetchFeatures();
    }, [user, authLoading]);

    const check = (requires: PermissionName | PermissionName[], any = false) => {
        return hasPermission(organization, { requires, any });
    };
    const permissions = {
        canInviteMembers: check(PermissionName.invite_organization_users),
        canEditRoles: check(PermissionName.invite_organization_users),
        canRemoveMembers: check(PermissionName.remove_organization_users),
        canEdit: check(PermissionName.edit_organization),
        canCreateProjectExperiments: check(PermissionName.create_project_experiments),
        canCreateShareableExperiments: check(PermissionName.create_organization_shareable_experiments),
        canCreateTeams: check(PermissionName.create_organization_teams),
        canCreateApps: check(PermissionName.create_organization_teams),
        canEditApps: check(PermissionName.create_organization_teams),
        canCreateLiteratureCollections: check(PermissionName.create_organization_teams),
        canEditLiteratureCollections: check(PermissionName.create_organization_teams),
        canCreateGeoImports: check(PermissionName.create_organization_teams),
        canSubmitLettersOfSupport: check(PermissionName.create_organization_teams),
        canManageLiteratureLabels: check(PermissionName.create_organization_teams),
    };
    return {
        ...permissions,
        canAddExperimentForProjectType: (type?: ProjectType | string | null) => {
            return type === ProjectType.team
                ? permissions.canCreateProjectExperiments
                : permissions.canCreateShareableExperiments;
        },
        features,
        error,
    };
};

export default useOrganizationPermissions;
